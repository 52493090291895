<template>
  <div class="container">
    <div class="pageDrawer pageOpen">
      <div id="pageHeader">
        <div class="container max-800">
          <div id="infoHolder">
            <i class="tio-help-outlined"> </i>
            Privacy policy
          </div>
        </div>
        <div id="closeHolder">
          <img
            src="@/assets/img/close.svg"
            id="closePage"
            @click="closePage()"
          />
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="container text-start mt-5 pt-3 max-800">
        <article>
          <h1>Privacy policy</h1>
          <p class="fs-6 fst-italic fw-lighter mb-3">
            Last amended: 6 May 2021
          </p>
          <ul class="no-bullets">
            <li id="general">
              <h2>General information</h2>
              <ol>
                <li>
                  Scgames, acting as the data controller, values your integrity
                  and privacy immensely. We only gather information which is
                  really necessary for our operation and we protect it with
                  state of the art security.
                </li>
                <li>
                  Scgames may at its sole discretion request You to provide
                  additional information (e.g. residence address, country of
                  residence) and/or documents (s.a. ID, passport, driver's
                  license). This is important for the ‘Know Your Client’ process
                  as imposed by the anti-money laundering regulations.
                </li>
                <li>
                  Scgames will use Your personal data and information to give
                  You access to the Games, for monetary transactions to Your
                  Member Account and to provide You with promotional and
                  operational information for Our services.
                </li>
                <li>
                  This Privacy Policy constitutes the integral part of T&amp;Cs.
                  All terms used in this Privacy Policy shall be understood and
                  interpreted as set out in the T&amp;Cs.
                </li>
              </ol>
            </li>
            <li>
              <h2>Data Protection Laws and Regulations</h2>
              <ol>
                <li>
                  For the purposes of applicable data protection legislation,
                  the User hereby consents to the processing of any and all
                  personal data (in manual, electronic or any other form) in
                  connection with the User's transactions in relation to
                  Scgames. You approve and agree that Scgames may use Your
                  personal details for marketing purposes. We process personal
                  data on the basis of our legitimate business interests and for
                  the performance of the contract with You.
                </li>
                <li>
                  Scgames processes personal information collected via its
                  Websites for the purposes of:<br />Building up Your personal
                  profiles;<br />Providing you with information about Scgames
                  promotional offers; and<br />Complying with all regulatory
                  requirements, in particular those relating to the
                  identification of individuals under money laundering
                  legislation.
                </li>
                <li>
                  You may object to the processing of Your data, insofar as the
                  Agreement can still be performed.
                </li>
                <li>
                  Access to personal information is limited to the staff of
                  Scgames and/or its agents in accordance with their roles and
                  responsibilities in managing the above uses in accordance with
                  the applicable Data Protection Act.
                </li>
              </ol>
            </li>
            <li id="confidentiality">
              <h2>Confidentiality of Information</h2>
              <ol>
                <li>
                  Scgames shall ensure the confidentiality of the information it
                  has collected about individual Users and will not knowingly
                  allow access to this information to anyone outside of Scgames,
                  other than to the User himself or as described in this Privacy
                  Policy.
                </li>
                <li>
                  Being a User, You should ensure that Your Member Account
                  password remains confidential and we recommend that You take
                  appropriate steps to ensure others are not able to access Your
                  Member Account and other information.
                </li>
              </ol>
            </li>
            <li id="limits">
              <h2>Confidentiality Limits</h2>
              <ol>
                <li>
                  Legally Required Disclosures. Due to the existing legal,
                  regulatory and security environment, Scgames may be required,
                  under certain circumstances, to disclose personally
                  identifiable information about Our Users. Scgames will use its
                  best reasonable efforts to limit such disclosure to the
                  following: where Scgames believes in good faith that it is
                  required to do so in response to a subpoena, warrant or other
                  legal process; or where reasonably necessary to identify,
                  contact or bring legal action against persons or entities to
                  preserve and/or enforce the Scgames's rights. Further, We can,
                  and You authorize Us to, disclose Your User identification,
                  name, street address, city, state, zip code, country, phone
                  number, e-mail, etc. to third party agents of Scgames as We,
                  in Our sole discretion, believe necessary or appropriate in
                  connection with an investigation of fraud, intellectual
                  property infringement, piracy or other unlawful activity or
                  activity that may expose Us to legal liability.
                </li>
                <li>
                  Customer service representatives and other representatives of
                  Scgames may have access to the above-mentioned data in order
                  to provide quality customer service and to administer Our
                  business activities.
                </li>
                <li>
                  We may share Your personal data with any of Scgame’s agents
                  who may only use such data for strictly the same purposes as
                  Scgames shall specify and within the terms of T&amp;Cs and
                  this Privacy Policy.
                </li>
              </ol>
            </li>
            <li id="cookies">
              <h2>Cookies</h2>
              <ol>
                <li>
                  <span>
                    We may send You a temporary cookie when You visit the
                    Website. A cookie is a text-only string of information that
                    We place in Your computer's cookie file so that We can
                    remember who You are when You revisit our Website. We may
                    use the data that is generated from cookies to compile
                    statistical data on Your use of the Website. By entering and
                    using the Website and Scgames services You accept cookies
                    from Us. Please contact our support team:
                    <a href="mailto:support@scgames.io">support@scgames.io</a>,
                    if You require any further information about the use of
                    cookies.
                  </span>
                </li>
              </ol>
            </li>
            <li id="privacy">
              <h2>Consent to Privacy Policy</h2>
              <ol>
                <li>
                  By visiting website at scgames.io, You agree to the terms of
                  the Privacy Policy. We may occasionally update the Privacy
                  Policy and encourage You to periodically review it. Your
                  continued use of the Website and Scgames services constitutes
                  Your agreement to the Privacy Policy and any updates.
                </li>
              </ol>
            </li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    document.body.style.overflow = "hidden";
    return {
      history: window.history,
    };
  },
  methods: {
    closePage() {
      this.history.back();
      document.body.style.removeProperty("overflow");
    },
  },
};
</script>
<style scoped>
.aboutList {
  margin: 0px;
  padding: 0px;
  text-align: left;
  list-style-type: none;
  padding-top: 10px;
}
.aboutList li {
  padding: 15px;
  line-height: 25px;
}
</style>
